import { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SVGs from '../utils/svgs';
import { getVehicleInfoFromCarDetailsReferrer, buildVehicleLabel } from '../utils/utils';
import { Vehicle } from '../../../api/types';

const PageHeader = () => {
    const VEHICLE_ATTRIBUTES = ['make', 'model', 'trim'];

    const [vehicleInfo, setVehicleInfo] = useState<Vehicle[]>([]);
    const [vehicleLabel, setVehicleLabel] = useState<string>('');

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger as any);
        setVehicleInfoFromPageReferrer();
        initFixedHeaderOnScroll();
    }, []);

    const setVehicleInfoFromPageReferrer = () => {
        getVehicleInfoFromCarDetailsReferrer().then((vehicleInfo: Vehicle[]) => {
            setVehicleInfo(vehicleInfo);
            setVehicleLabel(vehicleInfo.length ? buildVehicleLabel(vehicleInfo[0], VEHICLE_ATTRIBUTES) : '');
        });
    };

    const initFixedHeaderOnScroll = () => {
        function toggleHeaderVisibility(visible: boolean) {
            gsap.set('.buying-online-page-header--container', { y: visible ? 64 : 0 });
        }

        (ScrollTrigger as any).create({
            trigger: '#main',
            start: 'top top',
            endTrigger: '#footer',
            end: 'bottom top',
            onEnter: () => {
                toggleHeaderVisibility(true);
            },
            onLeaveBack: () => {
                toggleHeaderVisibility(false);
            },
            invalidateOnRefresh: true,
        });
    };

    return (
        <div className="buying-online-page-header">
            {vehicleLabel.length ? (
                <div className="buying-online-page-header--back">
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            window.history.back();
                        }}
                        className="buying-online-page-header--back-button"
                    >
                        <span className="buying-online-page-header--back-arrow">{SVGs.arrowIcon}</span>
                        <span className="buying-online-page-header--back-label">
                            Back{' '}
                            <span className="buying-online-page-header--back-label__desktop">to {vehicleLabel}</span>
                        </span>
                    </a>
                </div>
            ) : (
                <h1 className="buying-online-page-header--heading">Buying Online</h1>
            )}
            <div className="buying-online-page-header--cta">
                <a
                    id="page-header-cta"
                    href="/cars/all"
                    className="buying-online-page-header--cta-button"
                    data-kmx-analytics="searchInit"
                >
                    Find a Car
                </a>
            </div>
        </div>
    );
};

export default PageHeader;
