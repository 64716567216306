import { useEffect } from 'react';
import { gsap, TimelineMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const LoveItOrReturnIt = () => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger as any);
        initScrollAnimations();
    }, []);

    const initScrollAnimations = () => {
        const tl = new TimelineMax();
        tl.to(
            '.buying-online-love-it--bordered-overlay',
            {
                'border-left-width': 0,
                'border-right-width': 0,
                'border-top-width': 0,
                'border-bottom-width': 0,
                ease: 'Power3.easeOut',
                duration: 3,
            },
            '-=3'
        );
        tl.from(
            '.buying-online-love-it--darkened-overlay',
            { autoAlpha: 0, ease: 'Power3.easeOut', duration: 3 },
            '-=3'
        );
        tl.from(
            '.buying-online-love-it--content-container',
            { opacity: 0, y: 250, ease: 'Power3.easeOut', duration: 3 },
            '-=2'
        );

        (ScrollTrigger as any).create({
            trigger: '#buying-online-love-it--container',
            start: 'top-=64 top',
            end: `bottom-=128 top`,
            pin: true,
            invalidateOnRefresh: true,
        });

        (ScrollTrigger as any).create({
            trigger: '#buying-online-love-it--container',
            start: 'top-=244 top',
            scrub: true,
            animation: tl,
        });
    };

    return (
        <div className="buying-online-love-it">
            <div className="buying-online-love-it--darkened-overlay" />
            <div className="buying-online-love-it--bordered-overlay" />
            <div className="buying-online-love-it--max-width-container">
                <div className="buying-online-love-it--content-container">
                    <h2 className="buying-online-love-it--heading">
                        The way <br /> car buying <br /> should be
                    </h2>
                    <p className="buying-online-love-it--copy">Upfront prices. CarMax Certified quality.</p>
                </div>
            </div>
        </div>
    );
};

export default LoveItOrReturnIt;
