import Icons from '../../../../../utilities/icons';
import './QuestionLink.scss';

interface IQuestionLinkProps {
    url: string;
    text: string;
}
export const QuestionLink = (props: IQuestionLinkProps) => {
    return (
        <a className="faq-question-link" href={props.url}>
            {props.text}
            <span>
                {Icons.rightArrowIcon}
            </span>
        </a>
    );
};
