import { useContext, useEffect, useState } from 'react';
import { getRecentlyViewedVehicleTiles } from '../../../utilities/vehicleTileBuilder';
import { IVehicleTile } from '../../../types/vehicleTile';
import { MAX_RECENTLY_VIEWED_VEHICLE_TILES } from '../utils/constants';
import * as savedVehicles from '../utils/savedVehicles';
import { getStoreId } from '../../../utilities/visitor-cookie';
import { MiniHomebase } from './MiniHomebase';
import { MiniHomebasePusher } from './MiniHomebasePusher';
import { removeViewedVehicleItemLocalStorage } from '../../home/utilities/viewedVehiclesTileBuilder';
import TileKebabMenuItemSnackbar from '../../home/hero/tile-kebab-menu/TileKebabMenuItemSnackbar';
import { FBSContext } from '../../../context';

interface ISnackBar {
    isOpen: boolean;
    text: string;
}

export const MiniHomebaseContainer = () => {
    const [hasLoadedSavedVehiclesAssets, setHasLoadedSavedVehiclesAssets] = useState(false);
    const [viewedVehicleTileList, setViewedVehicleTileList] = useState<IVehicleTile[]>([]);
    const [snackbar, setSnackbar] = useState<ISnackBar>({ isOpen: false, text: '' });
    const context = useContext(FBSContext);

    useEffect(() => {
        loadSavedVehiclesAssets();
        loadRecentlyViewedVehicleTiles();
    }, []);

    const loadSavedVehiclesAssets = () => {
        savedVehicles.loadSavedVehiclesAssets(() => {
            setHasLoadedSavedVehiclesAssets(true);
        });
    };

    const loadRecentlyViewedVehicleTiles = (): void => {
        getRecentlyViewedVehicleTiles(MAX_RECENTLY_VIEWED_VEHICLE_TILES, getStoreId()).then(
            (vehicleTileList: IVehicleTile[]) => {
                setViewedVehicleTileList(vehicleTileList);
                context.setVehicleList(vehicleTileList);
            }
        );
    };

    const updatedViewedTiles = (tileToRemove: IVehicleTile) => {
        const newViewedTileList: IVehicleTile[] = viewedVehicleTileList.filter(
            (tileData) => tileData.stockNumber !== tileToRemove.stockNumber
        );

        removeViewedVehicleItemLocalStorage(tileToRemove);
        setViewedVehicleTileList(newViewedTileList);
        setSnackbar({ isOpen: true, text: 'Removed from your recently viewed' });
    };

    const closeSnackbar = () => {
        setSnackbar({ isOpen: false, text: '' });
    };

    return hasLoadedSavedVehiclesAssets && viewedVehicleTileList.length > 0 ? (
        <>
            <MiniHomebase
                onOpenSnackBar={setSnackbar}
                viewedVehicleTileList={viewedVehicleTileList}
                updatedViewedTiles={updatedViewedTiles}
            />
            <TileKebabMenuItemSnackbar
                icon={undefined}
                isOpen={snackbar.isOpen}
                textLabel={snackbar.text}
                cancelAction={closeSnackbar}
            />
        </>
    ) : (
        <MiniHomebasePusher />
    );
};
