import { useEffect } from 'react';
import { gsap, TimelineMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from '../vendor/gsap/DrawSVGPlugin';
import SVGs from '../utils/svgs';

const Steps = () => {
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger as any, DrawSVGPlugin as any);
        registerAnimations();
    }, []);

    const step1Transition = () => {
        const pillTransition = new TimelineMax();
        pillTransition.fromTo(
            '.buying-online-steps--step-pill-outer-label',
            { y: 120 },
            { y: 0, ease: 'back.out(.75)', duration: 0.75 }
        );
        pillTransition.fromTo(
            '.buying-online-steps--step-pill',
            { y: 120 },
            { y: 0, ease: 'back.out(.75)', duration: 0.75 },
            '-=.625'
        );
        pillTransition.from(
            '.buying-online-steps--step-pill-animated-content',
            { opacity: 0, duration: 0.75 },
            '-=.75'
        );

        (ScrollTrigger as any).create({
            trigger: '.buying-online-steps--step-1',
            start: 'top center',
            animation: pillTransition,
            toggleActions: 'play reverse play reverse',
        });
    };

    const step2Transition = () => {
        const textCommuncation = new TimelineMax();
        textCommuncation.fromTo(
            '.buying-online-steps--communication-bubble-1',
            { y: 80 },
            { y: 0, ease: 'back.out(.75)', duration: 0.75 }
        );
        textCommuncation.from('.buying-online-steps--communication-bubble-1', { opacity: 0, duration: 0.75 }, '-=.75');
        textCommuncation.fromTo(
            '.buying-online-steps--communication-bubble-2',
            { y: 80 },
            { y: 0, ease: 'back.out(.75)', duration: 0.75 }
        );
        textCommuncation.from('.buying-online-steps--communication-bubble-2', { opacity: 0, duration: 0.75 }, '-=.75');

        (ScrollTrigger as any).create({
            trigger: '.buying-online-steps--step-2',
            start: 'top-=150 center',
            animation: textCommuncation,
            toggleActions: 'play reverse play reverse',
        });
    };

    const step3Transition = () => {
        const signatureModal = new TimelineMax();
        signatureModal.from('.buying-online-steps--signature-container', {
            scale: 0.5,
            opacity: 0,
            ease: 'back.out(2.25)',
            duration: 0.625,
        });
        signatureModal.from(
            '.buying-online-steps--signature-underline',
            { width: 0, ease: 'Power3.easeOut', duration: 0.5 },
            '-=.125'
        );
        signatureModal.from(
            '.buying-online-steps--signature-sign-flag',
            { opacity: 0, y: -45, ease: 'back.out(1.7)', duration: 0.5 },
            '-=.3625'
        );
        signatureModal.from('#buying-online-steps--signature-svg path', { drawSVG: false, duration: 1.5 });

        (ScrollTrigger as any).create({
            trigger: '.buying-online-steps--step-3',
            start: 'top center',
            animation: signatureModal,
            toggleActions: 'play reverse play reverse',
        });
    };

    const registerAnimations = () => {
        step1Transition();
        step2Transition();
        step3Transition();
    };

    return (
        <div className="buying-online-steps">
            {/* STEP 1 */}
            <section className="buying-online-steps--step-container buying-online-steps--step-1">
                <div className="buying-online-steps--copy-container">
                    <h2 className="buying-online-steps--step-heading">1. Choose</h2>
                    <p className="buying-online-steps--step-subheading">
                        Once you find a car to buy, decide how you want it. We'll call to talk next steps.
                    </p>
                </div>
                <div className="buying-online-steps--step-image-flex-container">
                    <div className="buying-online-steps--square-img-container">
                        <img
                            src="/home/images/buying-online/steps/buyonline-delivery.jpg"
                            alt="blue vehicle delivered to customer's house"
                        />
                        <div className="buying-online-steps--step-pill-animated-content">
                            <h3 className="buying-online-steps--step-pill-outer-label">Delivery to</h3>
                            <div className="buying-online-steps--step-pill">
                                <span className="buying-online-steps--step-pill-icon">{SVGs.homeIcon}</span>
                                <span className="buying-online-steps--step-pill-label">Home or work</span>
                            </div>
                        </div>
                    </div>
                    <div className="buying-online-steps--square-img-container">
                        <img
                            src="/home/images/buying-online/steps/buyonline-epu.jpg"
                            alt="blue vehicle sitting in parking spot at CarMax location"
                        />
                        <div className="buying-online-steps--step-pill-animated-content">
                            <h3 className="buying-online-steps--step-pill-outer-label">Express pickup at</h3>
                            <div className="buying-online-steps--step-pill">
                                <span className="buying-online-steps--step-pill-icon">{SVGs.locationIcon}</span>
                                <span className="buying-online-steps--step-pill-label">CarMax store</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* STEP 2 */}
            <section className="buying-online-steps--step-container buying-online-steps--step-2">
                <div className="buying-online-steps--copy-container">
                    <h2 className="buying-online-steps--step-heading">2. Buy</h2>
                    <p className="buying-online-steps--step-subheading">
                        Fill in your details from home. We provide remote support along the way.
                    </p>
                </div>
                <div className="buying-online-steps--image-container">
                    <div className="buying-online-steps--wide-img-container">
                        <picture>
                            <source
                                media="(max-width: 599px)"
                                srcSet="/home/images/buying-online/steps/buyonline-buy-sm.jpg"
                            />
                            <source
                                media="(min-width: 600px)"
                                srcSet="/home/images/buying-online/steps/buyonline-buy.jpg"
                            />
                            <img
                                src="/home/images/buying-online/steps/buyonline-buy-sm.jpg"
                                alt="couple in kitchen looking at the CarMax website on a tablet"
                            />
                        </picture>
                    </div>
                    <ul className="buying-online-steps--communication-container">
                        <li className="buying-online-steps--communication-bubble buying-online-steps--communication-bubble-1">
                            <div className="buying-online-steps--communication-avatar">
                                <img
                                    src="/home/images/buying-online/steps/buy-jordan.png"
                                    alt="profile picture of CarMax representative, Jordan"
                                />
                            </div>
                            <div className="buying-online-steps--communication-copy-container">
                                <div>
                                    <span className="buying-online-steps--communication-name">Jordan</span>
                                    <span className="buying-online-steps--communication-time">2:30pm</span>
                                </div>
                                <div className="buying-online-steps--communication-sentence">
                                    Your financing is all set and is ready for your review
                                </div>
                            </div>
                        </li>
                        <li className="buying-online-steps--communication-bubble buying-online-steps--communication-bubble-2">
                            <div className="buying-online-steps--communication-avatar">
                                <img
                                    src="/home/images/buying-online/steps/buy-monique.png"
                                    alt="profile picture of CarMax customer, Monique"
                                />
                            </div>
                            <div className="buying-online-steps--communication-copy-container">
                                <div>
                                    <span className="buying-online-steps--communication-name">Monique</span>
                                    <span className="buying-online-steps--communication-time">2:35pm</span>
                                </div>
                                <div className="buying-online-steps--communication-sentence">
                                    Great! Can you also help me get a trade-in offer?
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            {/* STEP 3 */}
            <section className="buying-online-steps--step-container buying-online-steps--step-3">
                <div className="buying-online-steps--copy-container">
                    <h2 className="buying-online-steps--step-heading">3. eSign</h2>
                    <p className="buying-online-steps--step-subheading">
                        Once your order is all set, we schedule your delivery or express pickup{' '}
                        <span className="dagger">&dagger;</span>.
                    </p>
                </div>
                <div className="buying-online-steps--image-container">
                    <div className="buying-online-steps--overflow-img-container">
                        <img
                            src="/home/images/buying-online/steps/buyonline-esign.png"
                            alt="digital signature written on phone in landscape orientation"
                        />
                    </div>
                    <div className="buying-online-steps--signature-container">
                        <div className="buying-online-steps--signature-underline" />
                        <div className="buying-online-steps--signature-sign-flag">
                            <div className="buying-online-steps--signature-sign-label">Sign</div>
                        </div>
                        <div className="buying-online-steps--signature">{SVGs.signaturePath}</div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Steps;
